.features-tiles-item-image {
  display: inline-flex;
  border-radius: 10%;
  padding: 5px;
}

.svg-white {
 background-color: white;
 padding: 5px;
 border-radius: 10%;
}
